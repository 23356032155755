// Angular
import { Component, OnInit, ElementRef, viewChild, inject } from '@angular/core';
// Service
import { SplashScreenService } from './splash-screen.service';
import { LogoService } from '../../core/logo.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  // Private
  private readonly logoService = inject(LogoService);
  private splashScreenService = inject(SplashScreenService);

  // Public 
  logoSrc: string;

  // View Child
  readonly splashScreen = viewChild<ElementRef>('splashScreen');

  /**
   * On init
   * @memberof SplashScreenComponent
   */
  ngOnInit(): void {
    this.logoSrc = this.logoService.getLogoSrc();
    this.splashScreenService.init(this.splashScreen());
  }
}
